import { ReactNode, createContext, useContext, useState } from 'react';
import { AreaName, FormEntity, SettingsContextEntity } from '../data/types';
import { DEBUG_AUTOFILL, MockEntity } from '../data/data';
import { getProductModels } from '../data/utils';

const DEFAULT_VALUE: SettingsContextEntity = {
  area: DEBUG_AUTOFILL ? 'Neuroradiology' : undefined,
  product: DEBUG_AUTOFILL ? 'iNstroke 6F' : '',
  model: DEBUG_AUTOFILL ? 'Text + photo (new product, web...)' : '',

  form: DEBUG_AUTOFILL
    ? MockEntity
    : {
        lang: 'es',
        date: new Date(),
        title: '',
        description: '',
        speakerName: '',
        speakerPosition: '',
      },

  setArea: () => {},
  setProduct: () => {},
  setModel: () => {},
  setForm: () => {},

  getActiveModel: () => undefined,
};

const SettingsContext = createContext(DEFAULT_VALUE);

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [value, setValue] = useState<SettingsContextEntity>(DEFAULT_VALUE);

  const getActiveModel = () => {
    if (value.area && value.model) {
      const models = getProductModels(value.area, value.product);

      const activeModel = models.find((item) => item.name === value.model);
      return activeModel;
    }
    return undefined;
  };

  const providerValue = {
    ...value,
    setArea: (area: AreaName) => setValue({ ...value, area }),
    setProduct: (product: string) => setValue({ ...value, product }),
    setModel: (model: string) => setValue({ ...value, model }),
    setForm: (form: FormEntity) => setValue({ ...value, form }),

    getActiveModel,
  };

  return (
    <SettingsContext.Provider value={providerValue}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
