import logo from './logo.png';

import iconCorporate from './icon.corporate.png';
import iconCoronary from './icon.coronary.png';
import iconPeripheral from './icon.peripheral.png';
import icoNeuroradiology from './icon.neuroradiology.png';

import modelEmpty from './model.empty.png';
//products 1
// --
// products 2
import productCoronaryAngiolite from './product.coronary.angiolite.png';
import productCoronaryCapturer from './product.coronary.capturer.png';
import productCoronaryEssentialPro from './product.coronary.essential_pro.png';
import productCoronaryNaviscore from './product.coronary.naviscore.png';
import productCoronaryNavitian from './product.coronary.navitian.png';
import productCoronaryNcXperience from './product.coronary.nc_xperience.png';
import productCoronaryXperiencePro from './product.coronary.xperience_pro.png';
// products 3
import productPeripheralAngioliteBtk from './product.peripheral.angiolite_btk.png';
import productPeripheralICover from './product.peripheral.icover.png';
import productPeripheralIVolutionPro from './product.peripheral.ivolution_pro.png';
import productPeripheralIVolutionHandle from './product.peripheral.ivolution_handle.png';
import productPeripheralLuminor from './product.peripheral.luminor.png';
import productPeripheralOceanus from './product.peripheral.oceanus.png';
import productPeripheralRestorer from './product.peripheral.restorer.png';
import productPeripheralSergeant from './product.peripheral.sergeant.png';
//products 4
import productsNeuroradiologyIndeep from './product.neuroradiology.indeep.png';
import productsNeuroradiologyInstroke4 from './product.neuroradiology.instroke4.png';
import productsNeuroradiologyInstroke from './product.neuroradiology.instroke.png';
import productsNeuroradiologyInedit from './product.neuroradiology.inedit.png';
import productsNeuroradiologyIntercept from './product.neuroradiology.intercept.png';

// models 1
import modelCorporateCongress from './model.corporate.congress.png';
import modelCorporateCongressPresentation from './model.corporate.congress-presentation.png';
import modelCorporatePhotoFrame from './model.corporate.photoframe.png';
import modelCorporateSymposium from './model.corporate.symposium.png';
import modelCorporatePublication from './model.corporate.publication.png';
import modelCorporateText from './model.corporate.text.png';

// models 2
import modelCoronaryCongressAngiolite from './model.coronary.congress.angiolite.png';
import modelCoronaryCongressCapturer from './model.coronary.congress.capturer.png';
import modelCoronaryCongressEssential_pro from './model.coronary.congress.essential_pro.png';
import modelCoronaryCongressNaviscore from './model.coronary.congress.naviscore.png';
import modelCoronaryCongressNavitian from './model.coronary.congress.navitian.png';
import modelCoronaryCongressNc_xperience from './model.coronary.congress.nc_xperience.png';
import modelCoronaryCongressXperience_pro from './model.coronary.congress.xperience_pro.png';

import modelCoronaryCongressPresentationAngiolite from './model.coronary.congress-presentation.angiolite.png';
import modelCoronaryCongressPresentationCapturer from './model.coronary.congress-presentation.capturer.png';
import modelCoronaryCongressPresentationEssential_pro from './model.coronary.congress-presentation.essential_pro.png';
import modelCoronaryCongressPresentationNaviscore from './model.coronary.congress-presentation.naviscore.png';
import modelCoronaryCongressPresentationNavitian from './model.coronary.congress-presentation.navitian.png';
import modelCoronaryCongressPresentationNc_xperience from './model.coronary.congress-presentation.nc_xperience.png';
import modelCoronaryCongressPresentationXperience_pro from './model.coronary.congress-presentation.xperience_pro.png';

import modelCoronarySymposiumAngiolite from './model.coronary.symposium.angiolite.png';
import modelCoronarySymposiumCapturer from './model.coronary.symposium.capturer.png';
import modelCoronarySymposiumEssential_pro from './model.coronary.symposium.essential_pro.png';
import modelCoronarySymposiumNaviscore from './model.coronary.symposium.naviscore.png';
import modelCoronarySymposiumNavitian from './model.coronary.symposium.navitian.png';
import modelCoronarySymposiumNc_xperience from './model.coronary.symposium.nc_xperience.png';
import modelCoronarySymposiumXperience_pro from './model.coronary.symposium.xperience_pro.png';

import modelCoronaryPhotoFrame from './model.coronary.photoframe.png';
import modelCoronaryPublication from './model.coronary.publication.png';
import modelCoronaryText from './model.coronary.text.png';

// models 3
import modelPeripheralCongressAngiolite_btk from './model.peripheral.congress.angiolite_btk.png';
import modelPeripheralCongressPresentationAngiolite_btk from './model.peripheral.congress-presentation.angiolite_btk.png';
import modelPeripheralSymposiumAngiolite_btk from './model.peripheral.symposium.angiolite_btk.png';
import modelPeripheralPhotoFrame from './model.peripheral.photoframe.png';
import modelPeripheralPublication from './model.peripheral.publication.png';
import modelPeripheralText from './model.peripheral.text.png';

import modelPeripheralCongressIcover from './model.peripheral.congress.icover.png';
import modelPeripheralCongressPresentationIcover from './model.peripheral.congress-presentation.icover.png';
import modelPeripheralSymposiumIcover from './model.peripheral.symposium.icover.png';

import modelPeripheralCongressIvolution_pro from './model.peripheral.congress.ivolution_pro.png';
import modelPeripheralCongressPresentationIvolution_pro from './model.peripheral.congress-presentation.ivolution_pro.png';
import modelPeripheralSymposiumIvolution_pro from './model.peripheral.symposium.ivolution_pro.png';

import modelPeripheralCongressIvolution_handle from './model.peripheral.congress.ivolution_handle.png';
import modelPeripheralCongressPresentationIvolution_handle from './model.peripheral.congress-presentation.ivolution_handle.png';
import modelPeripheralSymposiumIvolution_handle from './model.peripheral.symposium.ivolution_handle.png';

import modelPeripheralCongressLuminor from './model.peripheral.congress.luminor.png';
import modelPeripheralCongressPresentationLuminor from './model.peripheral.congress-presentation.luminor.png';
import modelPeripheralSymposiumLuminor from './model.peripheral.symposium.luminor.png';

import modelPeripheralCongressOceanus from './model.peripheral.congress.oceanus.png';
import modelPeripheralCongressPresentationOceanus from './model.peripheral.congress-presentation.oceanus.png';
import modelPeripheralSymposiumOceanus from './model.peripheral.symposium.oceanus.png';

import modelPeripheralCongressRestorer from './model.peripheral.congress.restorer.png';
import modelPeripheralCongressPresentationRestorer from './model.peripheral.congress-presentation.restorer.png';
import modelPeripheralSymposiumRestorer from './model.peripheral.symposium.restorer.png';

import modelPeripheralCongressSergeant from './model.peripheral.congress.sergeant.png';
import modelPeripheralCongressPresentationSergeant from './model.peripheral.congress-presentation.sergeant.png';
import modelPeripheralSymposiumSergeant from './model.peripheral.symposium.sergeant.png';

// models 4
import modelNeuroradiologyCongressIndeep from './model.neuroradiology.congress.indeep.png';
import modelNeuroradiologyCongressPresentationIndeep from './model.neuroradiology.congress-presentation.indeep.png';
import modelNeuroradiologySymposiumIndeep from './model.neuroradiology.symposium.indeep.png';
import modelNeuroradiologyPhotoFrame from './model.neuroradiology.photoframe.png';
import modelNeuroradiologyPublication from './model.neuroradiology.publication.png';
import modelNeuroradiologyText from './model.neuroradiology.text.png';

import modelNeuroradiologyCongressInedit from './model.neuroradiology.congress.inedit.png';
import modelNeuroradiologyCongressPresentationInedit from './model.neuroradiology.congress-presentation.inedit.png';
import modelNeuroradiologySymposiumInedit from './model.neuroradiology.symposium.inedit.png';

import modelNeuroradiologyCongressInstroke from './model.neuroradiology.congress.instroke.png';
import modelNeuroradiologyCongressPresentationInstroke from './model.neuroradiology.congress-presentation.instroke.png';
import modelNeuroradiologySymposiumInstroke from './model.neuroradiology.symposium.instroke.png';

import modelNeuroradiologyCongressInstroke4 from './model.neuroradiology.congress.instroke4.png';
import modelNeuroradiologyCongressPresentationInstroke4 from './model.neuroradiology.congress-presentation.instroke4.png';
import modelNeuroradiologySymposiumInstroke4 from './model.neuroradiology.symposium.instroke4.png';

import modelNeuroradiologyCongressIntercept from './model.neuroradiology.congress.intercept.png';
import modelNeuroradiologyCongressPresentationIntercept from './model.neuroradiology.congress-presentation.intercept.png';
import modelNeuroradiologySymposiumIntercept from './model.neuroradiology.symposium.intercept.png';

// templates 1
import templateCorporateCongress from './template.corporate.congress.png';
import templateCorporateCongressPresentation from './template.corporate.congress-presentation.png';
import templateCorporatePhotoFrame from './template.corporate.photoframe.png';
import templateCorporateSymposium from './template.corporate.symposium.png';
import templateCorporatePublication from './template.corporate.publication.png';
import templateCorporateText from './template.corporate.text.png';

// templates 2
import templateCoronaryCongressAngiolite from './template.coronary.congress.angiolite.png';
import templateCoronaryCongressCapturer from './template.coronary.congress.capturer.png';
import templateCoronaryCongressEssential_pro from './template.coronary.congress.essential_pro.png';
import templateCoronaryCongressNaviscore from './template.coronary.congress.naviscore.png';
import templateCoronaryCongressNavitian from './template.coronary.congress.navitian.png';
import templateCoronaryCongressNc_xperience from './template.coronary.congress.nc_xperience.png';
import templateCoronaryCongressXperience_pro from './template.coronary.congress.xperience_pro.png';

import templateCoronaryCongressPresentationAngiolite from './template.coronary.congress-presentation.angiolite.png';
import templateCoronaryCongressPresentationCapturer from './template.coronary.congress-presentation.capturer.png';
import templateCoronaryCongressPresentationEssential_pro from './template.coronary.congress-presentation.essential_pro.png';
import templateCoronaryCongressPresentationNaviscore from './template.coronary.congress-presentation.naviscore.png';
import templateCoronaryCongressPresentationNavitian from './template.coronary.congress-presentation.navitian.png';
import templateCoronaryCongressPresentationNc_xperience from './template.coronary.congress-presentation.nc_xperience.png';
import templateCoronaryCongressPresentationXperience_pro from './template.coronary.congress-presentation.xperience_pro.png';

import templateCoronarySymposiumAngiolite from './template.coronary.symposium.angiolite.png';
import templateCoronarySymposiumCapturer from './template.coronary.symposium.capturer.png';
import templateCoronarySymposiumEssential_pro from './template.coronary.symposium.essential_pro.png';
import templateCoronarySymposiumNaviscore from './template.coronary.symposium.naviscore.png';
import templateCoronarySymposiumNavitian from './template.coronary.symposium.navitian.png';
import templateCoronarySymposiumNc_xperience from './template.coronary.symposium.nc_xperience.png';
import templateCoronarySymposiumXperience_pro from './template.coronary.symposium.xperience_pro.png';

import templateCoronaryPhotoFrame from './template.coronary.photoframe.png';
import templateCoronaryPublication from './template.coronary.publication.png';
import templateCoronaryText from './template.coronary.text.png';

// templates 3
import templatePeripheralCongressAngiolite_btk from './template.peripheral.congress.angiolite_btk.png';
import templatePeripheralCongressPresentationAngiolite_btk from './template.peripheral.congress-presentation.angiolite_btk.png';
import templatePeripheralSymposiumAngiolite_btk from './template.peripheral.symposium.angiolite_btk.png';
import templatePeripheralPhotoFrame from './template.peripheral.photoframe.png';
import templatePeripheralPublication from './template.peripheral.publication.png';
import templatePeripheralText from './template.peripheral.text.png';

import templatePeripheralCongressIcover from './template.peripheral.congress.icover.png';
import templatePeripheralCongressPresentationIcover from './template.peripheral.congress-presentation.icover.png';
import templatePeripheralSymposiumIcover from './template.peripheral.symposium.icover.png';

import templatePeripheralCongressIvolution_pro from './template.peripheral.congress.ivolution_pro.png';
import templatePeripheralCongressPresentationIvolution_pro from './template.peripheral.congress-presentation.ivolution_pro.png';
import templatePeripheralSymposiumIvolution_pro from './template.peripheral.symposium.ivolution_pro.png';

import templatePeripheralCongressIvolution_handle from './template.peripheral.congress.ivolution_handle.png';
import templatePeripheralCongressPresentationIvolution_handle from './template.peripheral.congress-presentation.ivolution_handle.png';
import templatePeripheralSymposiumIvolution_handle from './template.peripheral.symposium.ivolution_handle.png';

import templatePeripheralCongressLuminor from './template.peripheral.congress.luminor.png';
import templatePeripheralCongressPresentationLuminor from './template.peripheral.congress-presentation.luminor.png';
import templatePeripheralSymposiumLuminor from './template.peripheral.symposium.luminor.png';

import templatePeripheralCongressOceanus from './template.peripheral.congress.oceanus.png';
import templatePeripheralCongressPresentationOceanus from './template.peripheral.congress-presentation.oceanus.png';
import templatePeripheralSymposiumOceanus from './template.peripheral.symposium.oceanus.png';

import templatePeripheralCongressRestorer from './template.peripheral.congress.restorer.png';
import templatePeripheralCongressPresentationRestorer from './template.peripheral.congress-presentation.restorer.png';
import templatePeripheralSymposiumRestorer from './template.peripheral.symposium.restorer.png';

import templatePeripheralCongressSergeant from './template.peripheral.congress.sergeant.png';
import templatePeripheralCongressPresentationSergeant from './template.peripheral.congress-presentation.sergeant.png';
import templatePeripheralSymposiumSergeant from './template.peripheral.symposium.sergeant.png';

// templates 4
import templateNeuroradiologyCongressIndeep from './template.neuroradiology.congress.indeep.png';
import templateNeuroradiologyCongressPresentationIndeep from './template.neuroradiology.congress-presentation.indeep.png';
import templateNeuroradiologySymposiumIndeep from './template.neuroradiology.symposium.indeep.png';
import templateNeuroradiologyPhotoFrame from './template.neuroradiology.photoframe.png';
import templateNeuroradiologyPublication from './template.neuroradiology.publication.png';
import templateNeuroradiologyText from './template.neuroradiology.text.png';

import templateNeuroradiologyCongressInedit from './template.neuroradiology.congress.inedit.png';
import templateNeuroradiologyCongressPresentationInedit from './template.neuroradiology.congress-presentation.inedit.png';
import templateNeuroradiologySymposiumInedit from './template.neuroradiology.symposium.inedit.png';

import templateNeuroradiologyCongressInstroke from './template.neuroradiology.congress.instroke.png';
import templateNeuroradiologyCongressPresentationInstroke from './template.neuroradiology.congress-presentation.instroke.png';
import templateNeuroradiologySymposiumInstroke from './template.neuroradiology.symposium.instroke.png';

import templateNeuroradiologyCongressInstroke4 from './template.neuroradiology.congress.instroke4.png';
import templateNeuroradiologyCongressPresentationInstroke4 from './template.neuroradiology.congress-presentation.instroke4.png';
import templateNeuroradiologySymposiumInstroke4 from './template.neuroradiology.symposium.instroke4.png';

import templateNeuroradiologyCongressIntercept from './template.neuroradiology.congress.intercept.png';
import templateNeuroradiologyCongressPresentationIntercept from './template.neuroradiology.congress-presentation.intercept.png';
import templateNeuroradiologySymposiumIntercept from './template.neuroradiology.symposium.intercept.png';

export const images = {
  logo,

  iconCorporate,
  iconCoronary,
  iconPeripheral,
  icoNeuroradiology,

  modelEmpty,

  //products 1
  // --

  // products 2
  productCoronaryAngiolite,
  productCoronaryCapturer,
  productCoronaryEssentialPro,
  productCoronaryNaviscore,
  productCoronaryNavitian,
  productCoronaryNcXperience,
  productCoronaryXperiencePro,

  // products 3
  productPeripheralAngioliteBtk,
  productPeripheralICover,
  productPeripheralIVolutionPro,
  productPeripheralIVolutionHandle,
  productPeripheralLuminor,
  productPeripheralOceanus,
  productPeripheralRestorer,
  productPeripheralSergeant,

  //products 4
  productsNeuroradiologyIndeep,
  productsNeuroradiologyInstroke4,
  productsNeuroradiologyInstroke,
  productsNeuroradiologyInedit,
  productsNeuroradiologyIntercept,

  // models 1
  modelCorporateCongress,
  modelCorporateCongressPresentation,
  modelCorporatePhotoFrame,
  modelCorporateSymposium,
  modelCorporatePublication,
  modelCorporateText,

  // models 2
  modelCoronaryCongressAngiolite,
  modelCoronaryCongressCapturer,
  modelCoronaryCongressEssential_pro,
  modelCoronaryCongressNaviscore,
  modelCoronaryCongressNavitian,
  modelCoronaryCongressNc_xperience,
  modelCoronaryCongressXperience_pro,

  modelCoronaryCongressPresentationAngiolite,
  modelCoronaryCongressPresentationCapturer,
  modelCoronaryCongressPresentationEssential_pro,
  modelCoronaryCongressPresentationNaviscore,
  modelCoronaryCongressPresentationNavitian,
  modelCoronaryCongressPresentationNc_xperience,
  modelCoronaryCongressPresentationXperience_pro,

  modelCoronarySymposiumAngiolite,
  modelCoronarySymposiumCapturer,
  modelCoronarySymposiumEssential_pro,
  modelCoronarySymposiumNaviscore,
  modelCoronarySymposiumNavitian,
  modelCoronarySymposiumNc_xperience,
  modelCoronarySymposiumXperience_pro,

  modelCoronaryPhotoFrame,
  modelCoronaryPublication,
  modelCoronaryText,

  // models 3
  modelPeripheralCongressAngiolite_btk,
  modelPeripheralCongressPresentationAngiolite_btk,
  modelPeripheralSymposiumAngiolite_btk,
  modelPeripheralPhotoFrame,
  modelPeripheralPublication,
  modelPeripheralText,
  modelPeripheralCongressIcover,
  modelPeripheralCongressPresentationIcover,
  modelPeripheralSymposiumIcover,
  modelPeripheralCongressIvolution_pro,
  modelPeripheralCongressPresentationIvolution_pro,
  modelPeripheralSymposiumIvolution_pro,
  modelPeripheralCongressIvolution_handle,
  modelPeripheralCongressPresentationIvolution_handle,
  modelPeripheralSymposiumIvolution_handle,
  modelPeripheralCongressLuminor,
  modelPeripheralCongressPresentationLuminor,
  modelPeripheralSymposiumLuminor,
  modelPeripheralCongressOceanus,
  modelPeripheralCongressPresentationOceanus,
  modelPeripheralSymposiumOceanus,
  modelPeripheralCongressRestorer,
  modelPeripheralCongressPresentationRestorer,
  modelPeripheralSymposiumRestorer,
  modelPeripheralCongressSergeant,
  modelPeripheralCongressPresentationSergeant,
  modelPeripheralSymposiumSergeant,

  // models 4
  modelNeuroradiologyCongressIndeep,
  modelNeuroradiologyCongressPresentationIndeep,
  modelNeuroradiologySymposiumIndeep,
  modelNeuroradiologyPhotoFrame,
  modelNeuroradiologyPublication,
  modelNeuroradiologyText,

  modelNeuroradiologyCongressInedit,
  modelNeuroradiologyCongressPresentationInedit,
  modelNeuroradiologySymposiumInedit,

  modelNeuroradiologyCongressInstroke,
  modelNeuroradiologyCongressPresentationInstroke,
  modelNeuroradiologySymposiumInstroke,

  modelNeuroradiologyCongressInstroke4,
  modelNeuroradiologyCongressPresentationInstroke4,
  modelNeuroradiologySymposiumInstroke4,

  modelNeuroradiologyCongressIntercept,
  modelNeuroradiologyCongressPresentationIntercept,
  modelNeuroradiologySymposiumIntercept,

  // templates 1
  templateCorporateCongress,
  templateCorporateCongressPresentation,
  templateCorporatePhotoFrame,
  templateCorporateSymposium,
  templateCorporatePublication,
  templateCorporateText,

  // templates 2
  templateCoronaryCongressAngiolite,
  templateCoronaryCongressCapturer,
  templateCoronaryCongressEssential_pro,
  templateCoronaryCongressNaviscore,
  templateCoronaryCongressNavitian,
  templateCoronaryCongressNc_xperience,
  templateCoronaryCongressXperience_pro,
  templateCoronaryCongressPresentationAngiolite,
  templateCoronaryCongressPresentationCapturer,
  templateCoronaryCongressPresentationEssential_pro,
  templateCoronaryCongressPresentationNaviscore,
  templateCoronaryCongressPresentationNavitian,
  templateCoronaryCongressPresentationNc_xperience,
  templateCoronaryCongressPresentationXperience_pro,
  templateCoronarySymposiumAngiolite,
  templateCoronarySymposiumCapturer,
  templateCoronarySymposiumEssential_pro,
  templateCoronarySymposiumNaviscore,
  templateCoronarySymposiumNavitian,
  templateCoronarySymposiumNc_xperience,
  templateCoronarySymposiumXperience_pro,

  templateCoronaryPhotoFrame,
  templateCoronaryPublication,
  templateCoronaryText,

  // templates 3
  templatePeripheralCongressAngiolite_btk,
  templatePeripheralCongressPresentationAngiolite_btk,
  templatePeripheralSymposiumAngiolite_btk,
  templatePeripheralPhotoFrame,
  templatePeripheralPublication,
  templatePeripheralText,
  templatePeripheralCongressIcover,
  templatePeripheralCongressPresentationIcover,
  templatePeripheralSymposiumIcover,
  templatePeripheralCongressIvolution_pro,
  templatePeripheralCongressIvolution_handle,
  templatePeripheralCongressPresentationIvolution_handle,
  templatePeripheralSymposiumIvolution_handle,
  templatePeripheralCongressPresentationIvolution_pro,
  templatePeripheralSymposiumIvolution_pro,
  templatePeripheralCongressLuminor,
  templatePeripheralCongressPresentationLuminor,
  templatePeripheralSymposiumLuminor,
  templatePeripheralCongressOceanus,
  templatePeripheralCongressPresentationOceanus,
  templatePeripheralSymposiumOceanus,
  templatePeripheralCongressRestorer,
  templatePeripheralCongressPresentationRestorer,
  templatePeripheralSymposiumRestorer,
  templatePeripheralCongressSergeant,
  templatePeripheralCongressPresentationSergeant,
  templatePeripheralSymposiumSergeant,

  // templates 4
  templateNeuroradiologyCongressIndeep,
  templateNeuroradiologyCongressPresentationIndeep,
  templateNeuroradiologyPhotoFrame,
  templateNeuroradiologySymposiumIndeep,
  templateNeuroradiologySymposiumInedit,
  templateNeuroradiologySymposiumInstroke,
  templateNeuroradiologySymposiumIntercept,
  templateNeuroradiologyPublication,
  templateNeuroradiologyText,
  templateNeuroradiologyCongressInedit,
  templateNeuroradiologyCongressPresentationInedit,

  templateNeuroradiologyCongressInstroke,
  templateNeuroradiologyCongressPresentationInstroke,

  templateNeuroradiologyCongressInstroke4,
  templateNeuroradiologyCongressPresentationInstroke4,
  templateNeuroradiologySymposiumInstroke4,

  templateNeuroradiologyCongressIntercept,
  templateNeuroradiologyCongressPresentationIntercept,
};
