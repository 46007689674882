import { images } from '../assets/images';
import { AreaEntity, FormEntity, ModelEntity } from './types';

const modelsCorporate: ModelEntity[] = [
  {
    name: 'Congress',
    thumbnails: { default: images.modelCorporateCongress },
    templates: { default: images.templateCorporateCongress },
  },
  {
    name: 'Congress Presentation',
    thumbnails: { default: images.modelCorporateCongressPresentation },
    templates: { default: images.templateCorporateCongressPresentation },
  },
  {
    name: 'Photo frame at congress',
    thumbnails: { default: images.modelCorporatePhotoFrame },
    templates: { default: images.templateCorporatePhotoFrame },
  },
  {
    name: 'Sympossium / Own meeting',
    thumbnails: { default: images.modelCorporateSymposium },
    templates: { default: images.templateCorporateSymposium },
  },
  {
    name: 'Clinical Case Publication',
    thumbnails: { default: images.modelCorporatePublication },
    templates: { default: images.templateCorporatePublication },
  },
  {
    name: 'Text + photo (new product, web...)',
    thumbnails: { default: images.modelCorporateText },
    templates: { default: images.templateCorporateText },
  },
];

const modelsCoronary: ModelEntity[] = [
  {
    name: 'Congress',
    thumbnails: {
      angiolite: images.modelCoronaryCongressAngiolite,
      capturer: images.modelCoronaryCongressCapturer,
      'essential pro': images.modelCoronaryCongressEssential_pro,
      naviscore: images.modelCoronaryCongressNaviscore,
      navitian: images.modelCoronaryCongressNavitian,
      'NC xperience': images.modelCoronaryCongressNc_xperience,
      'xperience pro': images.modelCoronaryCongressXperience_pro,
    },
    templates: {
      angiolite: images.templateCoronaryCongressAngiolite,
      capturer: images.templateCoronaryCongressCapturer,
      'essential pro': images.templateCoronaryCongressEssential_pro,
      naviscore: images.templateCoronaryCongressNaviscore,
      navitian: images.templateCoronaryCongressNavitian,
      'NC xperience': images.templateCoronaryCongressNc_xperience,
      'xperience pro': images.templateCoronaryCongressXperience_pro,
    },
  },
  {
    name: 'Congress Presentation',
    thumbnails: {
      angiolite: images.modelCoronaryCongressPresentationAngiolite,
      capturer: images.modelCoronaryCongressPresentationCapturer,
      'essential pro': images.modelCoronaryCongressPresentationEssential_pro,
      naviscore: images.modelCoronaryCongressPresentationNaviscore,
      navitian: images.modelCoronaryCongressPresentationNavitian,
      'NC xperience': images.modelCoronaryCongressPresentationNc_xperience,
      'xperience pro': images.modelCoronaryCongressPresentationXperience_pro,
    },
    templates: {
      angiolite: images.templateCoronaryCongressPresentationAngiolite,
      capturer: images.templateCoronaryCongressPresentationCapturer,
      'essential pro': images.templateCoronaryCongressPresentationEssential_pro,
      naviscore: images.templateCoronaryCongressPresentationNaviscore,
      navitian: images.templateCoronaryCongressPresentationNavitian,
      'NC xperience': images.templateCoronaryCongressPresentationNc_xperience,
      'xperience pro': images.templateCoronaryCongressPresentationXperience_pro,
    },
  },
  {
    name: 'Photo frame at congress',
    thumbnails: { default: images.modelCoronaryPhotoFrame },
    templates: { default: images.templateCoronaryPhotoFrame },
  },
  {
    name: 'Sympossium / Own meeting',
    thumbnails: {
      angiolite: images.modelCoronarySymposiumAngiolite,
      capturer: images.modelCoronarySymposiumCapturer,
      'essential pro': images.modelCoronarySymposiumEssential_pro,
      naviscore: images.modelCoronarySymposiumNaviscore,
      navitian: images.modelCoronarySymposiumNavitian,
      'NC xperience': images.modelCoronarySymposiumNc_xperience,
      'xperience pro': images.modelCoronarySymposiumXperience_pro,
    },
    templates: {
      angiolite: images.templateCoronarySymposiumAngiolite,
      capturer: images.templateCoronarySymposiumCapturer,
      'essential pro': images.templateCoronarySymposiumEssential_pro,
      naviscore: images.templateCoronarySymposiumNaviscore,
      navitian: images.templateCoronarySymposiumNavitian,
      'NC xperience': images.templateCoronarySymposiumNc_xperience,
      'xperience pro': images.templateCoronarySymposiumXperience_pro,
    },
  },
  {
    name: 'Clinical Case Publication',
    thumbnails: { default: images.modelCoronaryPublication },
    templates: { default: images.templateCoronaryPublication },
  },
  {
    name: 'Text + photo (new product, web...)',
    thumbnails: { default: images.modelCoronaryText },
    templates: { default: images.templateCoronaryText },
  },
];

const modelsPeripheral: ModelEntity[] = [
  {
    name: 'Congress',
    thumbnails: {
      'angiolite BTK': images.modelPeripheralCongressAngiolite_btk,
      iCover: images.modelPeripheralCongressIcover,
      'iVolution pro': images.modelPeripheralCongressIvolution_pro,
      'iVolution pro handle': images.modelPeripheralCongressIvolution_handle,
      luminor: images.modelPeripheralCongressLuminor,
      oceanus: images.modelPeripheralCongressOceanus,
      restorer: images.modelPeripheralCongressRestorer,
      sergeant: images.modelPeripheralCongressSergeant,
    },
    templates: {
      'angiolite BTK': images.templatePeripheralCongressAngiolite_btk,
      iCover: images.templatePeripheralCongressIcover,
      'iVolution pro': images.templatePeripheralCongressIvolution_pro,
      'iVolution pro handle': images.templatePeripheralCongressIvolution_handle,
      luminor: images.templatePeripheralCongressLuminor,
      oceanus: images.templatePeripheralCongressOceanus,
      restorer: images.templatePeripheralCongressRestorer,
      sergeant: images.templatePeripheralCongressSergeant,
    },
  },
  {
    name: 'Congress Presentation',
    thumbnails: {
      'angiolite BTK': images.modelPeripheralCongressPresentationAngiolite_btk,
      iCover: images.modelPeripheralCongressPresentationIcover,
      'iVolution pro': images.modelPeripheralCongressPresentationIvolution_pro,
      'iVolution pro handle':
        images.modelPeripheralCongressPresentationIvolution_handle,
      luminor: images.modelPeripheralCongressPresentationLuminor,
      oceanus: images.modelPeripheralCongressPresentationOceanus,
      restorer: images.modelPeripheralCongressPresentationRestorer,
      sergeant: images.modelPeripheralCongressPresentationSergeant,
    },
    templates: {
      'angiolite BTK':
        images.templatePeripheralCongressPresentationAngiolite_btk,
      iCover: images.templatePeripheralCongressPresentationIcover,
      'iVolution pro':
        images.templatePeripheralCongressPresentationIvolution_pro,
      'iVolution pro handle':
        images.templatePeripheralCongressPresentationIvolution_handle,
      luminor: images.templatePeripheralCongressPresentationLuminor,
      oceanus: images.templatePeripheralCongressPresentationOceanus,
      restorer: images.templatePeripheralCongressPresentationRestorer,
      sergeant: images.templatePeripheralCongressPresentationSergeant,
    },
  },
  {
    name: 'Photo frame at congress',
    thumbnails: { default: images.modelPeripheralPhotoFrame },
    templates: { default: images.templatePeripheralPhotoFrame },
  },
  {
    name: 'Sympossium / Own meeting',
    thumbnails: {
      'angiolite BTK': images.modelPeripheralSymposiumAngiolite_btk,
      iCover: images.modelPeripheralSymposiumIcover,
      'iVolution pro': images.modelPeripheralSymposiumIvolution_pro,
      'iVolution pro handle': images.modelPeripheralSymposiumIvolution_handle,
      luminor: images.modelPeripheralSymposiumLuminor,
      oceanus: images.modelPeripheralSymposiumOceanus,
      restorer: images.modelPeripheralSymposiumRestorer,
      sergeant: images.modelPeripheralSymposiumSergeant,
    },
    templates: {
      'angiolite BTK': images.templatePeripheralSymposiumAngiolite_btk,
      iCover: images.templatePeripheralSymposiumIcover,
      'iVolution pro': images.templatePeripheralSymposiumIvolution_pro,
      'iVolution pro handle':
        images.templatePeripheralSymposiumIvolution_handle,
      luminor: images.templatePeripheralSymposiumLuminor,
      oceanus: images.templatePeripheralSymposiumOceanus,
      restorer: images.templatePeripheralSymposiumRestorer,
      sergeant: images.templatePeripheralSymposiumSergeant,
    },
  },
  {
    name: 'Clinical Case Publication',
    thumbnails: { default: images.modelPeripheralPublication },
    templates: { default: images.templatePeripheralPublication },
  },
  {
    name: 'Text + photo (new product, web...)',
    thumbnails: { default: images.modelPeripheralText },
    templates: { default: images.templatePeripheralText },
  },
];

const modelsNeuroradiology: ModelEntity[] = [
  {
    name: 'Congress',
    thumbnails: {
      iNdeep: images.modelNeuroradiologyCongressIndeep,
      iNedit: images.modelNeuroradiologyCongressInedit,
      'iNstroke 4F': images.modelNeuroradiologyCongressInstroke4,
      'iNstroke 6F': images.modelNeuroradiologyCongressInstroke,
      iNtercept: images.modelNeuroradiologyCongressIntercept,
    },
    templates: {
      iNdeep: images.templateNeuroradiologyCongressIndeep,
      iNedit: images.templateNeuroradiologyCongressInedit,
      'iNstroke 4F': images.templateNeuroradiologyCongressInstroke4,
      'iNstroke 6F': images.templateNeuroradiologyCongressInstroke,
      iNtercept: images.templateNeuroradiologyCongressIntercept,
    },
  },
  {
    name: 'Congress Presentation',
    thumbnails: {
      iNdeep: images.modelNeuroradiologyCongressPresentationIndeep,
      iNedit: images.modelNeuroradiologyCongressPresentationInedit,
      'iNstroke 4F': images.modelNeuroradiologyCongressPresentationInstroke4,
      'iNstroke 6F': images.modelNeuroradiologyCongressPresentationInstroke,
      iNtercept: images.modelNeuroradiologyCongressPresentationIntercept,
    },
    templates: {
      iNdeep: images.templateNeuroradiologyCongressPresentationIndeep,
      iNedit: images.templateNeuroradiologyCongressPresentationInedit,
      'iNstroke 4F': images.templateNeuroradiologyCongressPresentationInstroke4,
      'iNstroke 6F': images.templateNeuroradiologyCongressPresentationInstroke,
      iNtercept: images.templateNeuroradiologyCongressPresentationIntercept,
    },
  },
  {
    name: 'Photo frame at congress',
    thumbnails: {
      default: images.modelNeuroradiologyPhotoFrame,
    },
    templates: {
      default: images.templateNeuroradiologyPhotoFrame,
    },
  },
  {
    name: 'Sympossium / Own meeting',
    thumbnails: {
      iNdeep: images.modelNeuroradiologySymposiumIndeep,
      iNedit: images.modelNeuroradiologySymposiumInedit,
      'iNstroke 4F': images.modelNeuroradiologySymposiumInstroke4,
      'iNstroke 6F': images.modelNeuroradiologySymposiumInstroke,
      iNtercept: images.modelNeuroradiologySymposiumIntercept,
    },
    templates: {
      iNdeep: images.templateNeuroradiologySymposiumIndeep,
      iNedit: images.templateNeuroradiologySymposiumInedit,
      'iNstroke 4F': images.templateNeuroradiologySymposiumInstroke4,
      'iNstroke 6F': images.templateNeuroradiologySymposiumInstroke,
      iNtercept: images.templateNeuroradiologySymposiumIntercept,
    },
  },
  {
    name: 'Clinical Case Publication',
    thumbnails: { default: images.modelNeuroradiologyPublication },
    templates: { default: images.templateNeuroradiologyPublication },
  },
  {
    name: 'Text + photo (new product, web...)',
    thumbnails: { default: images.modelNeuroradiologyText },
    templates: { default: images.templateNeuroradiologyText },
  },
];

const data: AreaEntity[] = [
  {
    name: 'Corporate',
    icon: images.iconCorporate,
    color: undefined,
    products: [],
    models: modelsCorporate,
  },
  {
    name: 'Coronary',
    icon: images.iconCoronary,
    color: '#DF0084',
    products: [
      {
        name: 'angiolite',
        thumbnail: images.productCoronaryAngiolite,
        models: modelsCoronary,
      },
      {
        name: 'capturer',
        thumbnail: images.productCoronaryCapturer,
        models: modelsCoronary,
      },
      {
        name: 'essential pro',
        thumbnail: images.productCoronaryEssentialPro,
        models: modelsCoronary,
      },
      {
        name: 'naviscore',
        thumbnail: images.productCoronaryNaviscore,
        models: modelsCoronary,
      },
      {
        name: 'navitian',
        thumbnail: images.productCoronaryNavitian,
        models: modelsCoronary,
      },
      {
        name: 'NC xperience',
        thumbnail: images.productCoronaryNcXperience,
        models: modelsCoronary,
      },
      {
        name: 'xperience pro',
        thumbnail: images.productCoronaryXperiencePro,
        models: modelsCoronary,
      },
    ],
  },
  {
    name: 'Peripheral',
    icon: images.iconPeripheral,
    color: '#214297',
    products: [
      {
        name: 'angiolite BTK',
        thumbnail: images.productPeripheralAngioliteBtk,
        models: modelsPeripheral,
      },
      {
        name: 'iCover',
        thumbnail: images.productPeripheralICover,
        models: modelsPeripheral,
      },
      {
        name: 'iVolution pro',
        thumbnail: images.productPeripheralIVolutionPro,
        models: modelsPeripheral,
      },
      {
        name: 'iVolution pro handle',
        thumbnail: images.productPeripheralIVolutionHandle,
        models: modelsPeripheral,
      },
      {
        name: 'luminor',
        thumbnail: images.productPeripheralLuminor,
        models: modelsPeripheral,
      },
      {
        name: 'oceanus',
        thumbnail: images.productPeripheralOceanus,
        models: modelsPeripheral,
      },
      {
        name: 'restorer',
        thumbnail: images.productPeripheralRestorer,
        models: modelsPeripheral,
      },
      {
        name: 'sergeant',
        thumbnail: images.productPeripheralSergeant,
        models: modelsPeripheral,
      },
    ],
  },
  {
    name: 'Neuroradiology',
    icon: images.icoNeuroradiology,
    color: '#3AB56B',
    products: [
      {
        name: 'iNdeep',
        thumbnail: images.productsNeuroradiologyIndeep,
        models: modelsNeuroradiology,
      },
      {
        name: 'iNstroke 4F',
        thumbnail: images.productsNeuroradiologyInstroke4,
        models: modelsNeuroradiology,
      },
      {
        name: 'iNstroke 6F',
        thumbnail: images.productsNeuroradiologyInstroke,
        models: modelsNeuroradiology,
      },
      {
        name: 'iNedit',
        thumbnail: images.productsNeuroradiologyInedit,
        models: modelsNeuroradiology,
      },
      {
        name: 'iNtercept',
        thumbnail: images.productsNeuroradiologyIntercept,
        models: modelsNeuroradiology,
      },
    ],
  },
];

export default data;

export const DEBUG_AUTOFILL = false;

export const MockEntity: FormEntity = {
  lang: 'es',
  date: new Date(),
  title: 'Aenean leo ligula porttitor eu',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  speakerName: 'Ernesto Molina Carrón',
  speakerPosition: 'React Native Developer',
  photoFrame: undefined,
  speakerPhoto: undefined,
};
